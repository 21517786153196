var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Table, TableBody, TableContainer } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IStatus } from '../../../../@types/status';
import { useDateRangePicker } from '../../../../components/date-range-picker';
import LoadingTable from '../../../../components/loadingTable/LoadingTable';
import Scrollbar from '../../../../components/scrollbar';
import { TableHeadCustom, TableNoData, TablePaginationCustom, useTable, } from '../../../../components/table';
import { deleteOneCashPayment, getBooksCashDeskPayments } from '../../../../redux/slices/bookCashDeskPayments/actions';
import { useDispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import BooksCashDeskPaymentsRow from './BooksCashDeskPaymentsRow';
import BooksCashDeskPaymentsToolbar from './BooksCashDeskPaymentsToolbar';
// ----------------------------------------------------------------------
var TABLE_HEAD = [
    { id: 'cashedBy', label: 'cashed By', align: 'left' },
    { id: 'office', label: 'Office', align: 'left' },
    { id: 'amount', label: 'Amount', align: 'left' },
    { id: 'notes', label: 'Notes', align: 'left' },
    { id: 'cashedTo', label: 'cashed To', align: 'left' },
    { id: 'createdAt', label: 'Created At', align: 'left' },
    { label: '', align: 'center' },
];
// ----------------------------------------------------------------------
export default function BooksCashDeskPaymentsTable() {
    var _a;
    var _b = useTable({ defaultOrderBy: 'createdAt', defaultOrder: 'desc' }), dense = _b.dense, page = _b.page, order = _b.order, orderBy = _b.orderBy, rowsPerPage = _b.rowsPerPage, setPage = _b.setPage, 
    //
    selected = _b.selected, onSelectRow = _b.onSelectRow, 
    //
    onSort = _b.onSort, onChangeDense = _b.onChangeDense, onChangePage = _b.onChangePage, onChangeRowsPerPage = _b.onChangeRowsPerPage;
    var _c = useDateRangePicker(null, null), startDate = _c.startDate, endDate = _c.endDate, setStartDate = _c.setStartDate, setEndDate = _c.setEndDate, onChangeStartDate = _c.onChangeStartDate, onChangeEndDate = _c.onChangeEndDate, openPicker = _c.open, onOpenPicker = _c.onOpen, onClosePicker = _c.onClose, onResetPicker = _c.onReset, isSelectedValuePicker = _c.isSelected, isError = _c.isError, shortLabel = _c.shortLabel;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var _d = useState([]), tableData = _d[0], setTableData = _d[1];
    var _e = useState(null), cashedTo = _e[0], setCashedTo = _e[1];
    var _f = useState(null), cashedBy = _f[0], setCashedBy = _f[1];
    var _g = useState(null), filterOffice = _g[0], setFilterOffice = _g[1];
    var denseHeight = dense ? 52 : 72;
    var _h = useSelector(function (state) { return state.booksCashDeskPayments; }), booksCashDeskPayments = _h.booksCashDeskPayments, status = _h.status;
    useEffect(function () {
        dispatch(getBooksCashDeskPayments({
            page: page,
            limit: rowsPerPage,
            orderBy: orderBy,
            order: order,
            cashedBy: cashedBy,
            filterOffice: filterOffice,
            cashedTo: cashedTo,
            endDate: endDate,
            startDate: startDate,
        }));
    }, [
        dispatch,
        page,
        rowsPerPage,
        orderBy,
        order,
        cashedBy,
        filterOffice,
        cashedTo,
        startDate,
        endDate,
    ]);
    useEffect(function () {
        setTableData(booksCashDeskPayments === null || booksCashDeskPayments === void 0 ? void 0 : booksCashDeskPayments.docs);
    }, [booksCashDeskPayments]);
    var isFiltered = isSelectedValuePicker || !!filterOffice || !!cashedBy || !!cashedTo;
    var isNotFound = (status === IStatus.SUCCEEDED && !tableData.length) || !tableData.length;
    var dataInPage = tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    var handleViewRow = function (row) {
        navigate("".concat(PATH_DASHBOARD.books.cashDeskPayments.view(row._id)), { state: { expense: row } });
    };
    var handleEditRow = function (row) {
        navigate("".concat(PATH_DASHBOARD.books.cashDeskPayments.edit(row._id)), { state: { expense: row } });
    };
    var handleResetFilter = function () {
        setCashedBy(null);
        setCashedTo(null);
        setFilterOffice(null);
        setPage(0);
        if (setStartDate && setEndDate) {
            setStartDate(null);
            setEndDate(null);
        }
    };
    var handleDeleteRow = function (id) {
        dispatch(deleteOneCashPayment({ cashPaymentId: id }))
            .unwrap()
            .then(function (res) { return enqueueSnackbar("".concat(res === null || res === void 0 ? void 0 : res.message)); })
            .catch(function (err) { return enqueueSnackbar("".concat(err === null || err === void 0 ? void 0 : err.message), { variant: 'error' }); });
        if (page > 0) {
            if (dataInPage.length < 2) {
                setPage(page - 1);
            }
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(Card, { children: [_jsx(BooksCashDeskPaymentsToolbar, { onResetFilter: handleResetFilter, cashedTo: cashedTo, setCashedTo: setCashedTo, isFiltered: isFiltered, cashedBy: cashedBy, filterOffice: filterOffice, setCashedBy: setCashedBy, setFilterOffice: setFilterOffice, 
                    //
                    startDate: startDate, endDate: endDate, onChangeStartDate: onChangeStartDate, onChangeEndDate: onChangeEndDate, open: openPicker, onOpen: onOpenPicker, onClose: onClosePicker, onReset: onResetPicker, isSelected: isSelectedValuePicker, isError: isError, shortLabel: shortLabel }), _jsx(TableContainer, __assign({ sx: { position: 'relative', overflow: 'unset' } }, { children: _jsx(Scrollbar, __assign({ sx: { overflow: 'auto', maxHeight: 700 } }, { children: _jsxs(Table, __assign({ stickyHeader: true, "aria-label": "sticky table", size: dense ? 'small' : 'medium', sx: { minWidth: 800 } }, { children: [_jsx(TableHeadCustom, { order: order, orderBy: orderBy, headLabel: TABLE_HEAD, rowCount: tableData.length, numSelected: selected.length, onSort: onSort }), _jsxs(TableBody, { children: [status === IStatus.LOADING ? (_jsx(LoadingTable, { height: denseHeight, fields: TABLE_HEAD.length, rowsPerPage: rowsPerPage })) : (_jsx(_Fragment, { children: tableData === null || tableData === void 0 ? void 0 : tableData.map(function (row) { return (_jsx(BooksCashDeskPaymentsRow, { row: row, selected: selected.includes(row._id), onSelectRow: function () { return onSelectRow(row._id); }, onDeleteRow: function () {
                                                    handleDeleteRow(row._id);
                                                }, onEditRow: function () {
                                                    handleEditRow(row);
                                                }, onViewRow: function () {
                                                    handleViewRow(row);
                                                } }, row._id)); }) })), status === IStatus.SUCCEEDED && tableData.length === 0 && (_jsx(TableNoData, { isNotFound: isNotFound }))] })] })) })) })), _jsx(TablePaginationCustom, { count: ((_a = booksCashDeskPayments === null || booksCashDeskPayments === void 0 ? void 0 : booksCashDeskPayments.meta) === null || _a === void 0 ? void 0 : _a.totalDocs) || 0, page: page, rowsPerPage: rowsPerPage, onPageChange: onChangePage, onRowsPerPageChange: onChangeRowsPerPage, dense: dense, onChangeDense: onChangeDense })] }) }));
}
